<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="5">
        <v-card class="elevation-12">
          <v-toolbar dark flat>
            <v-toolbar-title>{{ $t("forms.signUp.title") }}</v-toolbar-title>
          </v-toolbar>
          <FormUser
            v-on:formSubmitted="submitForm"
            :error="errorMessage"
            :passwordRequired="true"
            :showConsent="true"
            :isLoading="isLoading"
          >
            <v-card-actions class="flex">
              <v-btn type="submit" color="success" class="flex-grow-1">
                {{ $t("forms.signUp.submit") }}
              </v-btn>
            </v-card-actions>

            <v-card-actions class="flex justify-center text-button">
              {{ $t("forms.login.or") }}
            </v-card-actions>

            <v-card-actions id="google" class="flex justify-center">
            </v-card-actions>
          </FormUser>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */

import Repository from "../store/api/repository";
import { mapGetters, mapMutations, mapActions } from "vuex";
import FormUser from "../components/FormUser";

export default {
  name: "SignUp",
  components: {
    FormUser
  },
  data() {
    return {
      error: null,
      isLoading: false
    };
  },
  head() {
    return {
      link: [
        {
          rel: "canonical",
          href: `https://alge-results.com/signup`
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      isDevSystem: "settings/isDevSystem"
    }),
    errorMessage() {
      return this.$i18n.t(this.error);
    }
  },
  methods: {
    ...mapMutations({
      setToken: "user/setToken",
      setUser: "user/setUser"
    }),
    ...mapActions({
      createUser: "user/createUser"
    }),
    shouldAppendValidClass(field) {
      return !field.$invalid && field.$model && field.$dirty;
    },
    submitForm(form) {
      this.error = null;
      this.isLoading = true;

      this.createUser(form)
        .then(response => {
          this.isLoading = false;
          if (response.data.status !== 0) {
            return Promise.reject(response);
          }

          if (response.data.data[0].roles.includes("TIMING_POINT_ACCOUNT")) {
            this.$router.push({ name: "Devices" });
          } else {
            this.$router.push({ name: "Dashboard" });
          }
        })
        .catch(response => {
          this.isLoading = false;
          switch (response.data.status) {
            case -1002:
              this.error = "forms.signUp.errors.existingEmail";
              break;
            default:
              this.error = "errors.unexpectedError";
          }
        });
    },
    initSignInWithGoogleClient() {
      const options = {
        client_id: this.isDevSystem
          ? process.env.VUE_APP_GOOGLE_CLIENT_DEV_ID
          : process.env.VUE_APP_GOOGLE_CLIENT_PROD_ID,
        callback: this.handleCredentialResponse
      };

      google.accounts.id.initialize(options);
    },
    renderSignInWithGoogleButton() {
      const parent = document.querySelector("#google");
      const options = {
        theme: "filled_blue",
        text: "signup_with"
      };

      google.accounts.id.renderButton(parent, options);
    },
    handleCredentialResponse(response) {
      const payload = {
        token: response.credential
      };

      Repository.post("user/oauth/login", payload)
        .then(response => {
          const token = response.headers.authorization;
          const user = response.data.data[0];
          this.setToken(token);
          this.setUser(user);

          if (response.data.status === 0) {
            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            } else {
              this.$router.push({ name: "Dashboard" });
            }
          } else if (response.data.status === -1013) {
            this.$router.push({ name: "Account" });
          } else {
            return Promise.reject(response);
          }
        })
        .catch(() => {
          this.error = "forms.login.errors.googleSignInFailure";
        });
    }
  },
  mounted() {
    this.initSignInWithGoogleClient();
    this.renderSignInWithGoogleButton();

    if (this.user) {
      this.$router.push({ name: "Dashboard" });
    }
  }
};
</script>
